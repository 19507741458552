export const eventList = [
  {
    title: "Tibetan New Year Losar",
    address: "St Aidan's Parish Church, Elford Place West, Leeds, LS8 5QD",
    date: "1st March 2025",
    description:
      "<img src='/img/losar.png'/> <br/> <p>We warmly invite you to join us in Leeds on Saturday, March 1st as we come together for Losar.</p> <p>This year, as we welcome the new year, we will also take a moment to remember those who tragically lost their lives in the recent earthquake in Tibet. As a mark of respect and solidarity with the earthquake victims, there will be no Gorshey.</p><p>The full schedule will include traditional Losar rituals, community activities, and delicious hot meals with dresil and khapsey served in the morning.</p><p>To help us plan for catering please let us know as soon as possible if you are attending.</p><p>Thank you and hope to see you all there!</p>",
    timestamp: new Date("2025-03-01"),
  },
  {
    title: "National Uprising Day",
    address: "Starting Location - Manchester Library",
    date: "10th March, 2025",
    description: "Information coming soon",
    timestamp: new Date("2025-03-10"),
  },
  {
    title: "Community Virtual Prayer Session",
    address: "Online event - link TBC",
    date: "11th June, 2025",
    description: "Information coming soon",
    timestamp: new Date("2025-06-11"),
  },
  {
    title: "H.H the Dalai Lama's Birthday Trunkar Celebration",
    address: "TBC",
    date: "6th July, 2025",
    description: "Information coming soon",
    timestamp: new Date("2025-07-06"),
  },
  {
    title: "Annual Summer Picnic",
    address: "Hesley Wood Scout Centre, Sheffield",
    date: "August 22nd - August 25th, 2025",
    description: "Information coming soon",
    timestamp: new Date("2025-08-22"),
  },
  {
    title: "H.H the 14th Dalai Lama's Nobel Peace Prize",
    address: "TBC",
    date: "1st December, 2025",
    description: "Information coming soon",
    timestamp: new Date("2025-12-01"),
  },
  {
    title: "Fundraising Bicycle Ride from Manchester to Leeds",
    address: "Manchester to Leeds",
    date: "July 13th, 2024",
    description:
      "<p>The Tibetan Community UK North Association, with the support of community members Mr. Kunchok and Mr. Palden is organizing a cycling event during which we will cycle from Manchester to Leeds. This event is open to all, and we encourage your participation. Please indicate your interest by participating in the poll below so that we can make the necessary arrangements. This one-of-a-kind event aims to raise awareness about Tibet and promote your well-being. We appreciate any amount of donation you can provide. Your contribution will enable us to organise more campaigns in support of the Tibetan cause.</p>",
    timestamp: new Date("2024-07-13"),
  },
  {
    title: "Annual Summer Picnic",
    date: "Friday 23rd August 2024 - Monday 26th August 2024",
    address: "Samye Ling Monastery, Scotland, DG13 0QL",
    description: "<img src='/img/samye_ling.png'/>",
    timestamp: new Date("2024-08-23"),
  },
  {
    title: "Community Prayer Session",
    address: "Online event - link TBC",
    date: "September 15th, 2024",
    description: "<p>More information coming soon.</p>",
    timestamp: new Date("2024-09-15"),
  },
  {
    title: "His Holiness the Dalai Lama's Nobel Peace Prize",
    date: "Saturday, 7th December",
    address: "St David's Church, Rocky Ln, Liverpool, L16 1JA",
    description:
      "<p>We welcome all our friends and members to join in the celebration of His Holiness' Nobel Peace Prize award in Liverpool.</p><p>A day packed full of fun for people of all ages. We will have Tibetan (Gorshay) circle dance, cash bingo, games and cultural performances. Delicious hot food will be served on the day.</p><p>Please confirm your attendance with number of adults / children by emailing us, this will help us for planning catering.</p>",
    timestamp: new Date("2024-12-07"),
  },
  {
    title: "Community Prayer Session",
    address: "Online event - link TBC",
    date: "December 20th, 2024",
    description: "<p>More information coming soon.</p>",
    timestamp: new Date("2024-12-20"),
  },
  {
    title: "Annual Sports Meet",
    address: "Godwin Sports Centre, Northumberland Road, S10 2TY",
    date: "Saturday, 15th June 2024. 11:00 - 18:00",
    description:
      "<p>TCB North is excited to announce its first ever annual sports meet! <br><br>Join us for a wonderful day in Sheffield as we have activities for all: Football, badminton, gorshay and more.</p><p><br>There will also be plenty of games for children to join in the fun. As part of the event we will be selling food which will raise funds for the community.</p>",
    timestamp: new Date("2024-06-15"),
  },
  {
    title: "His Holiness the Dalai Lama's Birthday Trunkar Celebration",
    date: "Saturday, 6th July 2024, 11am - 7pm",
    address:
      "St Kentigerns Irish Social Club, 36 Wilbraham Road, M14 7DW, Manchester.",
    description:
      "<p>A day packed full of fun for people of all ages. We will have Tibetan (Gorshay) circle dance, cash bingo, games and cultural performances. Delicious hot food will be served on the day. <br><br> </p><p>Please let us know if you are able to attend by emailing info@tcbnorth.co.uk with the number of attendees. This will help us prepare catering. Payment can be made on the day or in advance, with TCB North members receiving a £5 discount on entry fees.</p>",
    timestamp: new Date("2024-07-06"),
  },
  {
    title: "March 10 National Uprising Day",
    date: "10th March, 2024",
    timestamp: new Date("2024-03-10"),
  },
  {
    title: "Tibetan New Year Losar",
    date: "10th February 2023",
    description: "Event hosted in Leeds.",
    timestamp: new Date("2023-02-10"),
  },
  {
    title: "His Holiness the Dalai Lama's Nobel Peace Prize",
    date: "9th December 2023",
    description: "Event hosted in Manchester.",
    timestamp: new Date("2023-12-09"),
  },
];
